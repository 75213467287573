import React, { useRef } from "react";
import emailjs, { send } from "@emailjs/browser";
export default function Contact() {
    const form = useRef();
    
    const sendEmail = (e) => {
        e.preventDefault();
        const contactMessage = document.getElementById('contact-message');
        const button = document.getElementById('contact-submit');
        emailjs.sendForm(
          'service_gvbb14x',
          'template_a944037',
          form.current,
          'xpY7UemGR-1GYIahJ'
        ).then((result) => {
          contactMessage.textContent = 'Message sent successfully ✅';
          setTimeout(() => {
            contactMessage.textContent = '';
          }, 5000);
          button.disabled = true;
          button.value = 'Please reload the page';
        }, (error) => {
          contactMessage.textContent = `Error sending message: ${error.text}`;
      })
      }
    return (
        <section className="contact" id="contact">
            <div className="contact-container">
                <div className="contact-left">
                    <div className="head">
                    <h3>Contact us</h3>
                    <h1>Drop us a line</h1>
                    <p>Fill out this form and we'll hop straight to it.</p>
                    <p>Got ideas on how we can improve your experience? Got a few couple Borg names you thought of? Drop us a line by any means.</p>
                    </div>
                    <div className="disclaim">
                    <h2>Disclaimer</h2>
                    <p>We handle inquiries on a case-by-case basis, promising to give yours the spotlight it deserves. So fire away with questions, ideas, or just a friendly 'hello' - we can't wait to dive into the delicious details! </p>
                    </div>
                </div>
                <div className="contact-right">
                    <h2>Send us a message</h2>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="input-wrap">
                        <label htmlFor="firstname">First Name</label>
                        <input className="contact-input" type="text" id="fname" name="firstname" placeholder="Enter first name" size={10} required/>
                        </div>
                        <div className="input-wrap">
                        <label htmlFor="lastname">Last Name</label>
                        <input className="contact-input" type="text" id="lname" name="lastname" placeholder="Enter last name" size={10} required/>
                        </div>
                        
                        <div className="input-wrap w-100">
                        <label htmlFor="email">Email address</label>
                        <input className="contact-input" type="email" name="email" id="email" placeholder="Enter email" size={30}/>
                        </div>
                        
                        <div className="input-wrap w-100 textarea">
                        <label htmlFor="message">How can we help?</label>
                        <textarea className="contact-input" rows={5} name="message" auto-complete='off' placeholder="Message" required></textarea>
                        </div>
                        <div className="input-wrap w-100">
                            <button type="submit" className="contact-submit" id="contact-submit">Send message</button>
                        </div>
                        <div id="contact-message"></div>
                    </form>
                </div>
            </div>
        </section>
        
    )
    
}