import React from "react";
import 'boxicons';
export default function BackToTop() {
  // Get the button:
  

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    let mybutton = document.getElementById("myBtn");
    if (
      document.body.scrollTop > 150 ||
      document.documentElement.scrollTop > 150
    ) {
      mybutton.classList.add('show')
    } else {
      mybutton.classList.remove('show')
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  return (
    <button onClick={topFunction} id="myBtn" title="Go to top">
      <box-icon name='up-arrow-alt'></box-icon>
    </button>
  );
}
