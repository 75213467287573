import './App.css';
import About from './components/About';
import BackToTop from './components/BackToTop';
import Banner from './components/Banner';
import Borg from './components/Borg';
import Contact from './components/Contact';
import Home from './components/Home';
import NavBar from './components/Navbar';
function App() {
  return (
    <div className="App">
      <NavBar/>
      <Home/>
      <About/>
      <Borg/>
      <Contact/>
      <BackToTop/>
    </div>
  );
}

export default App;
