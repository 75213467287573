import React from "react";
export default function About() {

    return (
        <section id="about">
            <div className="cc-container">
            <div className="left-col">
                <h1>How to borg</h1>
                <div className="steps">
                    <ul>
                        <li className="step"><span>1. </span>Fill a gallon plastic jar halfway.</li>
                        <li className="step"><span>2. </span>Pour in a small handle of Tito's <em>(750 mL)</em> s or a vodka of your choice.</li>
                        <li className="step"><span>3. </span>Pour in 2 packets of Mio (1.62 oz each)</li>
                    </ul>
                </div>
            </div>
            <div className="right-col card-body">
            <h1>What is a BORG?</h1>
            <p>BORG, an acronym for <span>Black Out Rage Gallon</span>, is no ordinary concoction. It is the elixir of the gods, meticulously crafted to transcend the boundaries of taste and sensation. To partake in this sacred ritual, one wields a half-filled gallon jug, symbolizing purity and vitality through water. This vessel becomes the crucible for an alchemical transformation. With each measured pour, BORG becomes an experience that invigorates the body and nourishes the soul. It is a communion with the divine, an invocation of primal energies, and a testament to the mastery of the craft. Join us in raising our jugs to the heavens, celebrating BORG, the cocktail of the gods.</p>
            </div>
            </div>
        </section>
    );
}